// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-delivery-js": () => import("/opt/build/repo/src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-events-js": () => import("/opt/build/repo/src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-findus-js": () => import("/opt/build/repo/src/pages/findus.js" /* webpackChunkName: "component---src-pages-findus-js" */),
  "component---src-pages-icecream-js": () => import("/opt/build/repo/src/pages/icecream.js" /* webpackChunkName: "component---src-pages-icecream-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qrscan-js": () => import("/opt/build/repo/src/pages/qrscan.js" /* webpackChunkName: "component---src-pages-qrscan-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

